import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import WL from 'constants/whiteLabel';
import Link from 'components/Link';
import Typography from '../Typography';
import s from './getStarted.module.css';

const GetStarted = () => (
  <section className={s.wrapper}>
    <div className={s.content}>
      <Typography type="title" className={s.title}><L id="asicHub.getStarted.title" /></Typography>
      <Typography type="text" className={s.text}><L id="asicHub.getStarted.text" /></Typography>
      <Link to={WL.getRegisterLink()} className={s.btn}><L id="asicHub.getStarted.btnTitle" /></Link>
    </div>
  </section>
);

export default GetStarted;

import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import Link from 'components/Link';
import Lottie from "react-lottie";
import ahubDesktopWideAnimation from "utils/animations/web_asic_hub_wide.json";
import ahubDesktopAnimation from "utils/animations/web_asic_hub.json";
import ahubMobileAnimation from "utils/animations/mobile_asic_hub.json";
import { useWindowSize } from 'react-use';
import cx from 'classnames';
import s from './intro.module.css';

const Intro = (props) => {
  const {width, height} = useWindowSize();

  const isMob = width <= 480;
  const isDesktop = width > 480 && width <= 1240;
  const isDesktopWide = width > 1240;

  return (
    <section className={s.wrapper}>
      <div className={s.imgContainer}>
        <div className={s.content}>
          <div className={s.title}><L id="asicHub.intro.title" /></div>
          <div className={s.text}>
            <L
              id="asicHub.intro.text1"
              values={{
                time: <span className={s.coloredText}><L id="asicHub.intro.text2" /></span>,
                cost: <span className={s.coloredText}><L id="asicHub.intro.text3" /></span>,
              }}
            />
          </div>
          <div className={s.action}>
            <Link withLang to="/install/?ahub=1#ahub" className={s.btn}><L id="asicHub.intro.btnTitle" /></Link>
          </div>
        </div>

        {
          isDesktop ? (
            <div className={cx(s.animationWrap, s.desktop)}>
              <Lottie options={{animationData: ahubDesktopAnimation}} />
            </div>
          ) : null
        }
        {
          isDesktopWide ? (
            <div className={cx(s.animationWrap, s.desktopWide)}>
              <Lottie options={{animationData: ahubDesktopWideAnimation}} width="100%" />
            </div>
          ) : null
        }
        {
          isMob ? (
            <div className={cx(s.animationWrap)}>
              <Lottie options={{animationData: ahubMobileAnimation}} />
            </div>
          ) : null
        }
  
      </div>
    </section>
  );
};

export default Intro;

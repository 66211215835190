import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import Typography from '../Typography';
import machineImg from 'utils/images/machine-icon.svg';
import internetImg from 'utils/images/internet-icon.svg';
import winImg from 'utils/images/windows-64-icon.svg';
import linImg from 'utils/images/linux-64-icon.svg';
import imgScheme from 'utils/images/asichub-whatNeeds2.svg';
import imgSchemeMob from 'utils/images/asichub-whatNeeds-mob.svg';
import s from './whatNeeds.module.css';

const WhatNeeds = () => (
  <section className={s.wrapper}>
    <Typography type="title" className={s.title}><L id="asicHub.whatNeeds.title" /></Typography>
    <Typography type="text" className={s.subtitle}><L id="asicHub.whatNeeds.text"  /></Typography>
    <div className={s.flowBlock}>
      <div className={s.flowItem}>
        <img src={machineImg} alt="icon" />
        <div className={s.subtext}><L id="asicHub.whatNeeds.subtitle1" /></div>
        <Typography type="text" className={s.text}><L id="asicHub.whatNeeds.text1.1" /></Typography>
        <Typography type="text" className={s.text}><L id="asicHub.whatNeeds.text1.2" /></Typography>
      </div>
      <div className={s.flowItem}>
        <img src={winImg} alt="icon" />
        <img src={linImg} alt="icon" />
        <div className={s.subtext}><L id="asicHub.whatNeeds.subtitle2" /></div>
        <Typography type="text" className={s.text}><L id="asicHub.whatNeeds.text2.1" /></Typography>
        <Typography type="text" className={s.text}><L id="asicHub.whatNeeds.text2.2" /></Typography>
      </div>
      <div className={s.flowItem}>
        <img src={internetImg} alt="icon" />
        <div className={s.subtext}><L id="asicHub.whatNeeds.subtitle3" /></div>
        <Typography type="text" className={s.text}><L id="asicHub.whatNeeds.text3" values={{
          br: <br />,
        }} /></Typography>
      </div>
    </div>

    <div className={s.bottomBar} />
  </section>
);

export default WhatNeeds;

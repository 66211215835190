import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage as L } from 'react-intl';
import Link from 'components/Link';
import Typography from '../Typography';
import s from './blackBlock.module.css';

const BlackBlock = ({ title, text, btnPath, btnTitle, btnIcon, yellowBtn, withLang }) => (
  <section className={s.wrapper}>
    <div className={s.content}>
      <Typography type="title" className={s.title}><L id={title}/></Typography>
      <Typography type="text" className={s.text}><L id={text}/></Typography>
      <Link withLang={withLang} to={btnPath} className={cx(s.btn, { [s.yellowBtn]: yellowBtn })}>
        {btnIcon && (
          <Fragment>
            <img src={btnIcon} alt="book" className={s.btnIcon} />
          </Fragment>
        )}
        <L id={btnTitle}/>
      </Link>
    </div>
  </section>
);

BlackBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnPath: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
  btnIcon: PropTypes.string,
  withLang: PropTypes.bool,
  yellowBtn: PropTypes.bool,
};

export default BlackBlock;

import React, { Component } from "react";
import _get from 'lodash/get';
import cx from 'classnames';
import WL from 'constants/whiteLabel';
import Layout from 'components/layout';
import GetStarted from 'components/asichub/GetStarted';
import BlackBlock from 'components/asichub/BlackBlock';
import TextLeftBlock from 'components/asichub/TextLeftBlock';
import HiveOsFeatures from 'components/asichub/HiveOsFeatures';
import Intro from 'components/asichub/Intro';
import WhatToChoose from 'components/asichub/WhatToChoose';
import SupportedHardware from 'components/asichub/SupportedHardware';
import WhatNeeds from 'components/asichub/WhatNeeds';
import Bottom from 'components/Bottom/Bottom';
import bookBlack from 'utils/images/book_black.svg';
import imgNotifications from 'utils/images/asichub-notifications.png';
import imgWarranty from 'utils/images/asichub-warranty.svg';
import gs from 'pages/pages.module.css';

class AsicHub extends Component {
  render() {
    return (
      <div>
        <Layout withHeaderWrapper={false} lang={_get(this.props, "pageContext.lang", "en")} page="ASICHub">
          <Intro />

          <div className={cx(gs.content, gs.darkBg, gs.landingMargin)}>
            <div className={cx(gs.wrapper)}>
              <BlackBlock
                title="asicHub.installation.title"
                text="asicHub.installation.text"
                btnTitle="asicHub.installation.btnTitle"
                btnPath="/ASIC-Hub"
                btnIcon={bookBlack}
                withLang
              />
              <TextLeftBlock
                title="asicHub.warranty.title"
                text="asicHub.warranty.text"
                img={imgWarranty}
                imgWidth={250}
                isTextExtend
              />
              <HiveOsFeatures />
              <BlackBlock
                title="asicHub.configuration.title"
                text="asicHub.configuration.text"
                btnTitle="asicHub.configuration.btnTitle"
                btnPath={WL.getRegisterLink()}
                yellowBtn
              />
              <TextLeftBlock
                title="asicHub.notifications.title"
                text="asicHub.notifications.text"
                img={imgNotifications}
                imgWidth={360}
                alignCenter
              />
              <WhatToChoose />
              <SupportedHardware />
              <WhatNeeds />
              <GetStarted />
              <Bottom />
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default AsicHub;

import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import Typography from '../Typography';
import Lottie from "react-lottie";
import ahubFeaturesAnimation from "utils/animations/features_asic_hub.json";
import iconCpu from 'utils/images/asichub-cpu.svg';
import iconFan from 'utils/images/asichub-fan.svg';
import iconShield from 'utils/images/asichub-shield-check.svg';
import iconTemperature from 'utils/images/asichub-temperature.svg';
import iconZap from 'utils/images/asichub-zap.svg';
import iconGraph from 'utils/images/asichub-graph.svg';
import s from './hiveOsFeatures.module.css';

const HiveOsFeatures = () => (
  <section className={s.wrapper}>
    <div className={s.imgFeatures}>
      <Lottie options={{animationData: ahubFeaturesAnimation}} />
    </div>
    <div>
      <Typography type="title" className={s.title}><L id="asicHub.features.title" /></Typography>
      <Typography type="text" className={s.text}><L id="asicHub.features.text" /></Typography>
      <div className={s.listFeatures}>
        <div className={s.feature}>
          <div className={s.content}>
            <div className={s.imgWrap}>
              <img src={iconGraph} alt="icon" width="45" />
            </div>
            <span><L id="asicHub.features.item1" /></span>
          </div>
        </div>
        <div className={s.feature}>
          <div className={s.content}>
            <div className={s.imgWrap}>
              <img src={iconFan} alt="icon" width="40" />
            </div>
            <span><L id="asicHub.features.item3" /></span>
          </div>
        </div>
        <div className={s.feature}>
          <div className={s.content}>
            <div className={s.imgWrap}>
              <img src={iconTemperature} alt="icon" width="42" />
            </div>
            <span className={s.rightBlock}><L id="asicHub.features.item5" /></span>
          </div>
        </div>
        <div className={s.feature}>
          <div className={s.content}>
            <div className={s.imgWrap}>
              <img src={iconZap} alt="icon" width="38" />
            </div>
            <span><L id="asicHub.features.item2" /></span>
          </div>
        </div>
        <div className={s.feature}>
          <div className={s.content}>
            <div className={s.imgWrap}>
              <img src={iconShield} alt="icon" width="42" />
            </div>
            <span><L id="asicHub.features.item4" /></span>
          </div>
        </div>
        <div className={s.feature}>
          <div className={s.content}>
            <div className={s.imgWrap}>
              <img src={iconCpu} alt="icon" width="48" />
            </div>
            <span className={s.rightBlock}><L id="asicHub.features.item6" /></span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HiveOsFeatures;

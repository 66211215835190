import React from 'react';
import { FormattedMessage as L, injectIntl } from 'react-intl';
import cx from 'classnames';
import Typography from '../Typography';
import Link from 'components/Link';
import CollapseBlock from 'components/CollapseBlock';
import imgAsicHub from 'utils/images/hiveonAsicHub.svg';
import imgAsicMsk from 'utils/images/HiveonMskAsic.svg';
import advantegesIcon1 from 'utils/images/icon-advantages-1.svg';
import advantegesIcon2 from 'utils/images/icon-advantages-2.svg';
import advantegesIcon3 from 'utils/images/icon-advantages-3.svg';
import advantegesIcon4 from 'utils/images/icon-advantages-4.svg';
import s from './whatToChoose.module.css';

const WhatToChoose = ({ intl }) => (
  <section className={s.wrapper}>
    <Typography type="title" className={s.title}><L id="asicHub.WhatToChoose.title" /></Typography>
    <Typography type="text" className={s.subtext}><L id="asicHub.WhatToChoose.subtext" /></Typography>

    <div className={s.cards}>
      <div className={s.card}>
        <div className={s.cardBorder}>
          <div className={s.cardContent}>
            <div className={s.cardText}>
              <L id="asicHub.WhatToChoose.card1Text1" values={{
                highlight1: <span className={s.cardTextHighlight}><L id="asicHub.WhatToChoose.card1Text2" /></span>,
                highlight2: <span className={s.cardTextHighlight}><L id="asicHub.WhatToChoose.card1Text3" /></span>,
                highlight3: <span className={s.cardTextHighlight}><L id="asicHub.WhatToChoose.card1Text4" /></span>
              }} />
            </div>
          </div>
          <div className={s.cardFooter}>
            <div className={s.cardLogo}>
              <img src={imgAsicHub} alt="Hiveon ASIC hub" width="189" />
            </div>
            <div className={s.cardBtn}>
              <Link withLang to={'/install/?ahub=1#ahub'} className={cx(s.btn)}>
                <L id="asicHub.WhatToChoose.startNow" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={s.card}>
        <div className={s.cardBorder}>
          <div className={s.cardContent}>
            <div className={s.cardText}>
              <L id="asicHub.WhatToChoose.card2Text1" values={{
                highlight1: <span className={s.cardTextHighlight}><L id="asicHub.WhatToChoose.card2Text2" /></span>,
                highlight2: <span className={s.cardTextHighlight}><L id="asicHub.WhatToChoose.card2Text3" /></span>,
                highlight3: <span className={s.cardTextHighlight}><L id="asicHub.WhatToChoose.card2Text4" /></span>
              }} />
            </div>
          </div>
          <div className={s.cardFooter}>
            <div className={s.cardLogo}>
              <img src={imgAsicMsk} alt="Hiveon ASIC MSK miner" width="113" />
            </div>
            <div className={s.cardBtn}>
              <Link withLang to={'/asic'} className={cx(s.btn, s.btnInverse)}>
                <L id="asicHub.installation.btnTitle" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={s.advanteges}>
      <Typography type="title" className={s.subtitle}><L id="asicHub.WhatToChoose.subtitle" /></Typography>
      <Typography type="text" className={s.subtext}><L id="asicHub.WhatToChoose.subtext2" /></Typography>

      <div className={s.advantegesItems}>
        <div className={s.advantegesItem}>
          <div className={s.advantegesItemIcon}>
            <img src={advantegesIcon1} alt="icon" />
          </div>
          <div className={s.advantegesItemTitle}>
            <L id="asicHub.WhatToChoose.advantegesTitle1" />
          </div>
          <div className={s.advantegesItemText}>
            <L id="asicHub.WhatToChoose.advantegesText1" />
          </div>
        </div>

        <div className={s.advantegesItem}>
          <div className={s.advantegesItemIcon}>
            <img src={advantegesIcon3} alt="icon" />
          </div>
          <div className={s.advantegesItemTitle}>
            <L id="asicHub.WhatToChoose.advantegesTitle3" />
          </div>
          <div className={s.advantegesItemText}>
            <L id="asicHub.WhatToChoose.advantegesText3" />
          </div>
        </div>

        <div className={s.advantegesItem}>
          <div className={s.advantegesItemIcon}>
            <img src={advantegesIcon2} alt="icon" />
          </div>
          <div className={s.advantegesItemTitle}>
            <L id="asicHub.WhatToChoose.advantegesTitle2" />
          </div>
          <div className={s.advantegesItemText}>
            <L id="asicHub.WhatToChoose.advantegesText2" />
          </div>
        </div>

        <div className={s.advantegesItem}>
          <div className={s.advantegesItemIcon}>
            <img src={advantegesIcon4} alt="icon" />
          </div>
          <div className={s.advantegesItemTitle}>
            <L id="asicHub.WhatToChoose.advantegesTitle4" />
          </div>
          <div className={s.advantegesItemText}>
            <L id="asicHub.WhatToChoose.advantegesText4" />
          </div>
        </div>
      </div>
    </div>

    <div className={s.properties}>
      <Typography type="title" className={cx(s.subtitle, s.mobileCentered)}><L id="asicHub.WhatToChoose.subtitle2" /></Typography>

      <div className={s.propertiesItems}>
        <div className={s.propertiesItem}>
          <CollapseBlock
            title={intl.formatMessage({ id: "asicHub.WhatToChoose.propsQ1" })}
            titleClass={s.propertiesItemsTitle}
            openTitleClass={s.propertiesItemsTitleOpen}
          >
            <div className={s.propertiesItemText}>
              <L id="asicHub.WhatToChoose.propsA1" values={{
                link: <Link withLang to={'/hiveon-asic-firmware/'}><L id="asicHub.WhatToChoose.propsA1_2" /></Link>
              }} />
            </div>
          </CollapseBlock>
        </div>

        <div className={s.propertiesItem}>
          <CollapseBlock
            title={intl.formatMessage({ id: "asicHub.WhatToChoose.propsQ2" })}
            titleClass={s.propertiesItemsTitle}
            openTitleClass={s.propertiesItemsTitleOpen}
          >
            <div className={s.propertiesItemText}>
              <L id="asicHub.WhatToChoose.propsA2" values={{
                link: <a href="mailto:bee@hiveos.farm" className={s.link}><L id="asicHub.WhatToChoose.propsA2_2" /></a>
              }} />
            </div>
          </CollapseBlock>
        </div>

        <div className={s.propertiesItem}>
          <CollapseBlock
            title={intl.formatMessage({ id: "asicHub.WhatToChoose.propsQ3" })}
            titleClass={s.propertiesItemsTitle}
            openTitleClass={s.propertiesItemsTitleOpen}
          >
            <div className={s.propertiesItemText}>
              <p>
                <L id="asicHub.WhatToChoose.propsA3" values={{
                  linkPrices: <Link withLang to={'/pricing'}><L id="asicHub.WhatToChoose.propsA3_2" /></Link>,
                  linkDiscounts: <Link withLang to={'/pricing#discounts'}><L id="asicHub.WhatToChoose.propsA3_3" /></Link>
                }} />
              </p>
            </div>
          </CollapseBlock>
        </div>

        <div className={s.propertiesItem}>
          <CollapseBlock
            title={intl.formatMessage({ id: "asicHub.WhatToChoose.propsQ4" })}
            titleClass={s.propertiesItemsTitle}
            openTitleClass={s.propertiesItemsTitleOpen}
          >
            <div className={s.propertiesItemText}>
              <p>
                <L id="asicHub.WhatToChoose.propsA4" />
              </p>
            </div>
          </CollapseBlock>
        </div>
      </div>
    </div>
  </section>
);

export default injectIntl(WhatToChoose);

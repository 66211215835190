import imgWhatsMiner from 'utils/images/asichub-whatsminer@2x.png';
import imgAvalon from 'utils/images/asichub-avalon@2x.png';
import imgBitmain from 'utils/images/asichub-bitmain@2x.png';
import imgInnosilicon from 'utils/images/asichub-innosilicon@2x.png';

export const SUPPORTED_HARDWARE_LAYOUT = [
  {
    title: 'Bitmain',
    hardware: {
      firstRow: ['Series 19','S19', 'S19Pro', 'T19'],
      secondRow: ['Series 3', 'Series 9', 'Series 11', 'Series 15', 'Series 17'],
    },
    img: imgBitmain,
    widthImg: 71,
    heightImg: 78,
  },
  {
    title: 'WhatsMiner',
    hardware: {
      firstRow: ['M20S', 'M21S', 'M30S', 'M30S+', 'M30S++', 'M31S', 'M31S+', 'M3X', 'M32'],
      secondRow: [],
    },
    img: imgWhatsMiner,
    widthImg: 79,
    heightImg: 86,
  },
  {
    title: 'Avalon (Canaan)',
    hardware: {
      firstRow: ['10 Series', '1047', '1066', '1066Pro', '1146', '1166', '1246'],
      secondRow: [],
    },
    img: imgAvalon,
    widthImg: 80,
    heightImg: 95,
  },
  {
    title: 'Innosilicon',
    hardware:  {
      firstRow: ['A10L', 'A9+', 'T2*', 'T3*'],
      secondRow: [],
    },
    img: imgInnosilicon,
    widthImg: 79,
    heightImg: 87,
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage as L } from 'react-intl';
import Typography from '../Typography';
import s from './textLeftBlock.module.css';

const TextLeftBlock = ({ title, text, img, imgWidth, alignCenter, isTextExtend }) => (
  <section className={cx(s.wrapper, {
    [s.isTextExtend]: isTextExtend,
  })}>
    <div className={s.textWrap}>
      <Typography type="title" className={cx(s.title, {[s.alignCenter]: alignCenter})}><L id={title} /></Typography>
      <Typography type="text" className={cx(s.text, {[s.alignCenter]: alignCenter})}><L id={text} /></Typography>
    </div>
    <div className={s.imgWrap}>
      <img src={img} width={imgWidth} alt=""/>
    </div>
  </section>
);

TextLeftBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  imgWidth: PropTypes.number.isRequired,
};

export default TextLeftBlock;

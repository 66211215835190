import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import cx from 'classnames';
import Typography from '../Typography';
import { SUPPORTED_HARDWARE_LAYOUT } from 'constants/asicHub';

import s from './supportedHardware.module.css';

const SupportedHardware = () => (
  <section className={s.wrapper}>
    <Typography type="title" className={s.title}><L id="asicHub.hardware.title" /></Typography>
    {SUPPORTED_HARDWARE_LAYOUT.map((item, index) => {
      const isBitmain = false;
      return (
      <div key={`${index}_hardwareBlock`} className={s.blackBlock}>
        <div className={s.contentWeb}>
          <div className={s.imgWrapper}>
            <img src={item.img} width={item.widthImg} style={{ 'minWidth': `${item.widthImg}px`, 'maxHeight': `${item.heightImg}px` }} height={item.heightImg} alt="hardware" />
          </div>
          <div>
            <div className={cx(s.subtitle, { [s.coloredSubtitle]: isBitmain })}>{item.title}</div>
            <div className={cx(s.text, s.hardwareRow)}>
              {item.hardware.firstRow.map((hardwareItem, index) => (
                <div
                  key={`${index}_hardware`}
                  className={cx(s.hardwareItem, { [s.coloredItem]: isBitmain })}
                >
                  {hardwareItem}
                </div>
              ))}
            </div>
            {item.hardware.secondRow.length > 0 && (<div className={cx(s.text, s.hardwareRow)}>
              {item.hardware.secondRow.map((hardwareItem, index) => (
                <div key={`${index}_hardware`} className={s.hardwareItem}>{hardwareItem}</div>
              ))}
            </div>)}
          </div>
        </div>
        <div className={s.contentMob}>
          <div>
            <img src={item.img} width={item.widthImg} style={{ 'minWidth': `${item.widthImg}px`, 'maxHeight': `${item.heightImg}px` }} height={item.heightImg} alt="hardware" />
            <div className={cx(s.subtitle, { [s.coloredSubtitle]: isBitmain })}>{item.title}</div>
          </div>
          <Typography type="text" className={cx(s.text, s.hardwareRow)}>
            {item.hardware.firstRow.map((hardwareItem, index) => (
              <div
                key={`${index}_hardware`}
                className={cx(s.hardwareItem, {[s.coloredItem]: false})}
              >
                {hardwareItem}
              </div>
            ))}
            {item.hardware.secondRow.length > 0 && (<div className={cx(s.text, s.hardwareRow)}>
              {item.hardware.secondRow.map((hardwareItem, index) => (
                <div key={`${index}_hardware`} className={s.hardwareItem}>{hardwareItem}</div>
              ))}
            </div>)}
          </Typography>
        </div>
      </div>
    )})}
    <div className={s.blockRequest}>
      <div className={s.textRequest}><L id="asicHub.hardware.requestText" /></div>
      <a href="mailto:bee@hiveos.farm" className={s.btn}><L id="asicHub.hardware.requestBtn" /></a>
    </div>
  </section>
);

export default SupportedHardware;
